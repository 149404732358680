<!-- 首页 -->

<template>
  <div class="home_page">
    <!-- <img style="width: 100%; height: 407px;" src="../../../assets/images/shouye.png"> -->
    <div class="gywm">关于我们</div>
    <div class="total ">
      <div class="left"></div>
      <div class="right">
        <div class="right1">
        </div>
        <div class="right2">宝妈灵活就业网</div>
        <div class="right3">全国首个面向育儿妈妈的灵活就业创业平台</div>
        <div class="right4"></div>
        <div class="right5">公司成立于2019年11月，由灵活科技公司投资控股，高管组建合伙人企业入股；</div>
        <div class="right6">以“让每个放学回家的孩子，都能吃上妈妈做的热饭”为使命，以“朝九晚四（早上9点上班，下午4点下班）”为产品特质，目标是以珠三角为中心，凝聚并打造中国孩子3-12岁（幼儿园到小学）的5000万育儿妈妈的灵活就业门户；并开发平台结算工具灵活宝；</div>
        <div class="right7">项目具有巨大和深远的社会公益意义，利国利民！项目一经推出，就得到了政府、妇联、媒体和社会各界的高度关注和支持，时任广州市分管领导签呈要求在全广州市推广，广东电视台、广州日报、南方人物周刊均予以大幅报道，项目启动当年被评选为黄埔区“我为群众办实事”十大民生项目，并被写入黄埔区政府工作报告。</div>
      </div>
    </div>
    <div class="xgzx">
      <div class="xgzxtxt centeredtxt">相关资讯</div>
      <div class="total2 centered">
        <div class="xgleft">
          <div class="xgleft1">2023.07.01</div>
          <router-link to="/page1" class="xgleft2">黄浦区妇联、人社局联合调研朝九晚四巾帼创客中心</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">各界关怀</div>
          <div class="xgleftleft"></div>
          <div class="xgleftright"></div>
          <div class="clean"></div>
          <div style="margin-top: 32px;" class="xgleft1">2023.06.01</div>
          <router-link to="/page2" class="xgleft2">巾帼妈妈灵活创业外展就业招聘服务活动</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">就业创业</div>
          <div class="xgleftbottom"></div>
        </div>
        <div class="xgright">
          <div class="xgleft1">2023.05.17</div>
          <router-link to="/page3" class="xgleft2">区创投项目 ——“促进就业，宝妈创业”线下宣传招募活动</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">就业创业</div>
          <div style="margin-top: 32px;" class="xgleft1">2023.02.07</div>
          <router-link to="/page4" class="xgleft2">区妇联王主席视察朝九晚四，推进妇女灵活就业工作</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">各界关怀</div>
          <div style="margin-top: 32px;" class="xgleft1">2022.09.06</div>
          <router-link to="/page5" class="xgleft2">黄埔区妇女就业创业技能培训顺利开展</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">就业创业</div>
          <div style="margin-top: 32px;" class="xgleft1">2022.08.23</div>
          <router-link to="/page6" class="xgleft2">公益创投 暖席广州 —— 朝九晚四“三步学会电商直播”主题培训</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">就业创业</div>
          <div style="margin-top: 32px;" class="xgleft1">2022.05.16</div>
          <router-link to="/page7" class="xgleft2">朝九晚四灵活用工进园区公益活动--走进莱迪创新科技园</router-link>
          <div style="margin-top: 32px;" class="xgleft1">2022.05.10</div>
          <router-link to="/page8" class="xgleft2">广州火炬高新技术创业服务中心与广州开发区科技企业孵化器协会领导参观指导朝九晚四巾帼创客中心</router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  },
  created() {

  }
}
</script>

<style lang="less" scoped>
@import "./home_page.less";
</style>

